<template>
    <div >
    <div v-if="isSubmited" class="body2">
      <img class="xuanzhong" src="../../assets/img/xuanzhong.png" alt="" />

      <div class="tijiao">已提交</div>

      <div class="tijiao2" style="">我们会在3个工作日内核对并安排邮寄，具体到货时间以快递为准，请您注意接听快递电话。</div>

      <div style="height:30px"></div>
    </div>

    <div v-else class="v-project-join">
      <img style="width: 100%;display: block;" src="https://sho-static.shulan.com/activities/gift-card/top.png" alt="">
      <div class="notice-wraper">
        <div class="title">味乐舒益生菌功效作用</div>

        <div class="subtitle">调理肠道健康</div>
        <div class="content" style="margin-bottom: 20px;">味乐舒益生菌主要成份是低聚果糖、麦芽糊精、Li01 院士专利菌、瑞士乳杆菌、长双歧杆菌、乳清蛋白、L-苹果酸，有多种不同的菌种可以在整个肠道粘膜和肠道末端发挥作用，可有效的阻碍，有害细菌的繁殖，也能够促进小肠绒毛的细胞分化，可有助于修复受到损伤的肠道黏膜，因此长期使用可以让整个肠道功能处于一个平稳的状态，减少腹泻和便秘的问题发生。</div>

        <div class="subtitle">增强免疫</div>
        <div class="content" style="margin-bottom: 20px;">味乐舒益生菌增强免疫的效果是非常不错的，采用了特殊的配方制作而成，首先是利用了微胶囊多层包埋技术，同时也添加了冷冻干燥技术和水活度控制技术，可以让益生菌到达肠道的地方发挥作用，能够有助于维持肠道的正常功能，就能够让营养成分得到正常的供应，长期坚持使用可提升人体的免疫功能。</div>

        <div class="subtitle">用法用量</div>
        <div class="content">直接食用或加入 50ml 温水/牛奶 (37C以下) 调匀饮用，每天 1~2 次，每次 1 袋。</div>

    </div> 

      <div>
        <!-- 自定义题目 -->
        <div class="" v-for="(item, index) in questionList" :key="item.id">
          <!-- subjectType null 类型 0-单选,1-多选,2-填空 地区3 日期4 -->
          <!-- 单选 -->
          <radio-input
            v-if="item.subjectType == 0"
            :title="item.subjectContent"
            :items="item.subjectOptions"
            @change="radioOnChange"
            :index="index"
            :isRequred="item.isRequired"
          />

          <!-- 多选 -->
          <checkbox-input
            v-else-if="item.subjectType == 1"
            :title="item.subjectContent"
            :items="item.subjectOptions"
            @change="checkboxOnChange"
            :index="index"
            :isRequred="item.isRequired"
          />

          <!-- 填空 -->
          <text-input
            v-else-if="item.subjectType == 2"
            :title="item.subjectContent"
            :placeholder="item.remark"
            @change="textOnChange"
            :index="index"
            :isRequred="item.isRequired"
          />

          <!-- 地区 -->
          <div v-else-if="item.subjectType == 3" @click="areaOnClick(index)">
            <location-input
              :title="item.subjectContent"
              :value="item.subjectResult"
              :placeholder="item.remark"
              :index="index"
              :isRequred="item.isRequired"
            />
          </div>

          <!-- 日期 -->
          <div v-else-if="item.subjectType == 4" @click="dateOnClick(index)">
            <date-input
              :title="item.subjectContent"
              :value="item.subjectResult"
              :placeholder="item.remark"
              :index="index"
              :isRequred="item.isRequired"
            />
          </div>
          <div class="height24"></div>
        </div>
      </div>
      <div class="tip">
   
        <!-- 互联网医院衷心感谢你的帮助， -->
        <br />
        <!-- 并保证你的信息仅用于指导产品开发，绝不外泄。 -->
      </div>
      <div class="height80"></div>

      <div class="height80"></div>

      <div class="height80"></div>

      <div class="g-safe-area-bottom"></div>

      <submit-btn
        @click="sumbitClick"
        style="position:fixed;bottom:0;left:0;"
        title="提交"
      />

      <van-popup v-model="showDatePicker" position="bottom"
        ><van-datetime-picker
          type="date"
          title="选择年月日"
          :minDate="minDate"
          :maxDate="maxDate"
          v-model="currentDate"
          :show-toolbar="true"
          @confirm="datePickerOnConfirm"
          @cancel="datePickerOnCancel"
          :formatter="formatter"
        >
        </van-datetime-picker
      ></van-popup>
      <van-popup v-model="showAreaPicker" position="bottom">
        <van-area
          title="请选择地区"
          :area-list="areaData"
          @confirm="areaPickerOnConfirm"
          @cancel="areaPickerOnCancel"
      /></van-popup>
    </div>
  </div>
</template>

<script>
import SectionView from "@/components/section-view.vue";
import SubmitBtn from "@/components/submit-btn.vue";
import TextInput from "@/components/input/text-input.vue";
import RadioInput from "@/components/input/radio-input.vue";
import DateInput from "@/components/input/date-input.vue";
import LocationInput from "../../components/input/location-input.vue";
import CheckboxInput from "../../components/input/checkbox-input.vue";
import { DatetimePicker, Popup, Area, Dialog, Toast } from "vant";
import { areaList } from "@vant/area-data";
export default {
  components: {
    SubmitBtn,
    SectionView,
    TextInput,
    RadioInput,
    DateInput,
    LocationInput,
    CheckboxInput,
    DatetimePicker,
    Popup,
    Area,
  },
  data() {
    return {
      areaData: areaList,
      // qid: '2022110816524233879109',
      qid: "2023010622552861191147",
      questionList: [],
      showDatePicker: false,
      showAreaPicker: false,
      currentDatePickerIndex: 0,
      currentAreaPickerIndex: 0,
      isBirthdayPicker: false,
      isCurrentAreaPicker: false,
      minDate: new Date(1920, 1, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      isSubmited: false,
    //   shareData: {
    //     title: "参与树兰互联网医院2022年度问卷调研，精美礼品等你来拿",
    //     desc: "快来参与调研吧。",
    //     link: window.location.href,
    //     imgUrl:
    //       "https://sho-static.shulan.com/activities/app-qr/app-qr-icon.png",
    //   },
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F7F9FC");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  mounted() {
   
    // this._jssdk();
    this.getDetailRequest();
    this.scrollToTop();

  },
  methods: {
    _jssdk() {
      // if (!this.isMobile) return;
      // if (process.env.NODE_ENV !== "production") return;
      const _self = this;
      this.$axios
        .post(
          `/wx/config/params?hospitalId=34-6&url=${decodeURIComponent(
            window.location.href
          )}`,
          {},
          { basurlType: "commonurl" }
        )
        .then((res) => {
          console.log(res);
          wx.config({
            debug: res.data.results.debug,
            appId: res.data.results.appid,
            timestamp: res.data.results.timestamp * 1,
            nonceStr: res.data.results.nonceStr,
            signature: res.data.results.signature,
            jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"],
          });
          wx.ready(function() {
            console.log("wx.ready");
            wx.onMenuShareAppMessage({
              title: "参与树兰互联网医院2022年度问卷调研，精美礼品等你来拿",
              desc: "快来参与调研吧。",
              link: window.location.href,
              imgUrl:
                "https://sho-static.shulan.com/activities/app-qr/app-qr-icon.png",
            });
            wx.onMenuShareTimeline({
              title: "参与树兰互联网医院2022年度问卷调研，精美礼品等你来拿",
              // desc: "参与树兰互联网医院2022年度问卷调研，精美礼品等你来拿",
              link: window.location.href,
              imgUrl:
                "https://sho-static.shulan.com/activities/app-qr/app-qr-icon.png",
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
    isInvalidString(str) {
      return str == null || str.length <= 0;
    },
    isValidPhoneNumber(phone) {
      if (this.isInvalidString(phone)) {
        return false;
      }
      if (phone.length !== 11) {
        return false;
      }
      if (phone[0] != "1") {
        return false;
      }
      return true;
    },
    formatter(type, val) {
      if (type === "month") {
        return `${val}月`;
      }
      if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    timeFormat(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }

      return year + "-" + month + "-" + day;
    },

    //同意,不同意
    agreeRadioOnChange(e) {
      // {"value":[2]}
      if (e.value && e.value.length > 0) {
        let id = e.value[0];
        this.agreeStatus = id;
      }
    },

    //性别
    genderRadioOnChange(e) {
      if (e.value && e.value.length > 0) {
        let id = e.value[0];
        this.sex = id;
      }
    },

    //填空
    textOnChange(e) {
      console.log(`textOnChange: ${JSON.stringify(e)}`);
      this.questionList[e.index].subjectResult = e.value;
    },

    //出生日期
    birthdayOnClick(e) {
      this.showDatePicker = true;
      this.isBirthdayPicker = true;
    },

    //所在省份和地区
    currentAreaOnClick(e) {
      this.showAreaPicker = true;
      this.isCurrentAreaPicker = true;
    },

    //地区
    areaOnClick(index) {
      this.showAreaPicker = true;
      this.currentAreaPickerIndex = index;
    },
    areaPickerOnConfirm(e) {
      console.log(`${JSON.stringify(e)}`);
      this.showAreaPicker = false;
      if (this.isCurrentAreaPicker == true) {
        this.isCurrentAreaPicker = false;
        this.areaAddress = e
          .map((item) => {
            return item.name;
          })
          .join(" ");
        return;
      }

      this.questionList[this.currentAreaPickerIndex].subjectResult = e
        .map((item) => {
          return item.name;
        })
        .join(" ");
    },
    areaPickerOnCancel(e) {
      this.showAreaPicker = false;
    },

    //日期
    dateOnClick(index) {
      this.showDatePicker = true;
      this.currentDatePickerIndex = index;
    },
    datePickerOnConfirm(e) {
      console.log(`${JSON.stringify(e)}`);
      this.showDatePicker = false;
      if (this.isBirthdayPicker == true) {
        this.isBirthdayPicker = false;
        this.birthday = this.timeFormat(e);
        return;
      }
      this.questionList[
        this.currentDatePickerIndex
      ].subjectResult = this.timeFormat(e);
    },
    datePickerOnCancel(e) {
      this.showDatePicker = false;
    },

    //单选
    radioOnChange(e) {
      console.log(`radioOnChange: ${JSON.stringify(e)}`);
      const subjectOptions = this.questionList[e.index].subjectOptions;

      let strList = [];
      for (let select of e.value) {
        for (let item of subjectOptions) {
          if (select == item.id) {
            strList.push(item.optionContent);
          }
        }
      }

      this.questionList[e.index].subjectOptionIds = JSON.stringify(e.value);
      this.questionList[e.index].subjectResult = strList.join(",");
    },

    //多选
    checkboxOnChange(e) {
      console.log(`checkboxOnChange: ${JSON.stringify(e)}`);

      const subjectOptions = this.questionList[e.index].subjectOptions;

      let strList = [];
      console.log(`e.value: ${JSON.stringify(e.value)}`);

      for (let select of e.value) {
        for (let item of subjectOptions) {
          if (select == item.id) {
            strList.push(item.optionContent);
          }
        }
      }

      this.questionList[e.index].subjectOptionIds = JSON.stringify(e.value);
      this.questionList[e.index].subjectResult = strList.join(",");
    },

    //地区
    locationOnChange(e) {
      console.log(`locationOnChange: ${JSON.stringify(e)}`);
      this.questionList[e.index].subjectResult = e.value;
    },

    //获取详情信息
    // getDetailRequest() {
    //   this.$axios
    //     .get(
    //       `/questionnaire/get/subjectAndOptionList/byQuestionnaireId?questionnaireId=${this.$route.query.questionnaireId}`
    //     )
    //     .then((res) => {
    //       this.questionList = res.data;
    //     });
    // },

    getDetailRequest() {
      Toast.loading({
        message: "正在加载...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(`/questionnaire/get/by/id?questionnaireId=${this.qid}`)
        .then((res) => {
          Toast.clear();
          const { title, subjects } = res.data;
          // this.title = title;
          this.questionList = subjects;
          // this.subjects = subjects;
        })
        .catch(() => {
          Toast.clear();
        });
    },

    submit() {
      var subject = [];
      console.log("subjects", this.subjects);
      for (const index in this.subjects) {
        const element = this.subjects[index];
        if (element.userAnswer) {
          if (element.subjectType == 0) {
            subject.push({
              subjectId: element.subjectId,
              subjectOptionIds: JSON.stringify([element.userAnswer]),
              subjectResult: element.subjectOptions
                .filter((e) => element.userAnswer == e.id)
                .map((e) => e.optionContent)
                .join(","),
            });
          } else if (element.subjectType == 1) {
            subject.push({
              subjectId: element.subjectId,
              subjectOptionIds: JSON.stringify(element.userAnswer),
              subjectResult: element.subjectOptions
                .filter((e) => element.userAnswer.indexOf(e.id) != -1)
                .map((e) => e.optionContent)
                .join(","),
            });
          } else if (element.subjectType == 2) {
            subject.push({
              subjectId: element.subjectId,
              subjectOptionIds: element.userAnswer,
              subjectResult: element.userAnswer,
            });
          }

          console.log("subject", subject);
        } else {
          this.$toast.fail("请完成所有试题再提交");
          return;
        }
      }
      Toast.loading({
        message: "正在提交...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(`/get/request/token`)
        .then((res) => {
          console.log("token", res);
          this.$axios
            .post(`/audio/video/visit/questionnaire/submit`, {
              reqToken: res.data,
              visitId: this.visitId,
              questionnaireId: this.qid,
              sourceChannel: "h5",
              subject,
            })
            .then((res) => {
              this.isSubmited = true;
              Toast.clear();
            })
            .catch(() => {
              Toast.clear();
              this.$toast.fail("提交失败，请重新提交");
            });
        })
        .catch(() => {
          Toast.clear();
          this.$toast.fail("提交失败，请重新提交");
        });
    },

    //提交请求
    submitReuqest() {
      // 名称	类型	是否必须	默认值	备注	其他信息
      // name	string 非必须 姓名
      // phone	string非必须手机号
      // family_phone	string非必须家属手机号
      // sex	string非必须性别（男，女）
      // birthday	string非必须生日 yyyy-mm-dd
      // areaAddress	string非必须地址
      // clinicalRecruitmentId	string非必须临床招募id
      // sourceChannel	string非必须来源 mini,app

      // subject	object []非必须已填 题目和选项
      // item 类型: object
      // subjectId	string必须题目id
      // subjectResult	string必须题目答案  英文逗号间隔，例："苹果,香蕉,橘子"，填空返回字符串
      // subjectOptionIds	string必须所选选项，json，例：[21,25,24,26]

      let submitData = {
        questionnaireId: this.qid,
        sourceChannel: "h5", //	string非必须来源 mini,app
        questionnaireRecordDetailList: this.questionList
          .filter((item) => {
            // 保留必填项和已填项
            if (
              item.isRequired == true ||
              !this.isInvalidString(item.subjectResult)
            ) {
              return true;
            }
            return false;
          })
          .map((item) => {
            return {
              subjectId: item.subjectId,
              subjectResult: item.subjectResult,
              subjectOptionIds: item.subjectOptionIds,
            };
          }),
      };

      console.log("submitData", submitData);

      this.$axios.get(`/get/request/token`).then((res) => {
        submitData["reqToken"] = res.data;
        this.$axios
          .post(`/questionnaire/record/submit`, submitData)
          .then((res) => {
            this.isSubmited = true;
          });
      });
    },

    //提交
    sumbitClick() {
      //自定义题目的必选提示

      if (this.questionList && this.questionList.length) {
        for (const index in this.questionList) {
          const item = this.questionList[index];
          const relIndex = parseInt(index) + 1;
          if (
            item.isRequired == true &&
            (item.subjectResult == null || item.subjectResult.length <= 0)
          ) {
            this.$toast(`请完成第${relIndex}题`);
            return;
            // <!-- subjectType null 类型 0-单选,1-多选,2-填空 地区3 日期4 -->
            // if (item.subjectType == 2) {
            //   this.$toast("请输入" + item.subjectContent);
            //   return;
            // } else {
            //   this.$toast("请选择" + item.subjectContent);
            //   return;
            // }
          }
        }
      }
      //提交请求
      this.submitReuqest();
    },
    goBackToRoot() {
      this.$router.go(-1);
      // if (window.history.length > 2) {
      //   let deepth = window.history.length - 1;
      //   this.$router.go(-deepth);
      // } else {
      //   // this.$router.go(-1);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-project-join {
  background: #fff;
  .notice-wraper {
    width: 702px;
    // height: 1058px;
    margin: 0 auto;
    background: #FFF9E9;
    border-radius: 0px 0px 24px 24px;
    padding: 30px;
    box-sizing: border-box;
  }

  .title {
    font-size: 40px;
    font-family: NotoSansCJKsc-Bold, NotoSansCJKsc;
    font-weight: bold;
    color: #9D5103;
    line-height: 59px;
    margin-bottom: 40px;

  }

  .subtitle {
    font-size: 34px;
    font-family: NotoSansCJKsc-Medium, NotoSansCJKsc;
    font-weight: 500;
    color: #CE7900;
    margin-bottom: 10px;
  }
  .content {
    font-size: 26px;
    font-family: NotoSansCJKsc-Regular, NotoSansCJKsc;
    font-weight: 400;
    color: #86550A;
    line-height: 38px;
  }
}
.tip {
  // width: 500px;
  margin: 50px auto 0;
  color: #12beae;
  text-align: center;
  font-size: 25px;
  font-family: PingFangSC-Normal, PingFang SC;
}

.body2 {
  height: 100%;
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.xuanzhong {
  margin: 150px auto 18px;
  width: 90px;
  height: 90px;
  display: block;
}
.tijiao {
  margin-top: 20px;
  font-size: 44px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #040814;
}
.tijiao2 {
  margin-top: 10px;
  font-size: 27px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
  color: #040814;
  line-height: 45px;
  margin: 20px 40px;
  letter-spacing: 3px;
  text-align: center;

}
</style>
